import React, { ReactElement } from 'react';
import { Layout } from '../components/Layout/Layout';
import { SpellLike } from '../components/SpellLike/SpellLike';
import { Intro } from '../modules/Intro/Intro';
import { TestimonialsMore } from '../modules/Testimonials/TestimonialsMore';
import { TESTIMONIALS_DATA } from '../data/testimonials';
import { Signup } from '../modules/Signup/Signup';
import { PricingMix } from '../modules/Pricing/PricingMix';
import { FAQ } from '../modules/FAQ/FAQ';

const WORDS = ['stunning', 'process', 'plans', 'mockups'];

const FAQPage = (): ReactElement => {
  return (
    <Layout>
      <FAQ/>
    </Layout>
  );
};

export default FAQPage;
